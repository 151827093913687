.view-staff {
  .view-content {
    display: flex;
    flex-wrap: wrap;
  }
  .views-row  {
    flex: 0 1 50%;
    text-align: center;
    padding: 20px;
    margin-bottom: 20px;
    h4 {
      color: $brand-success;
    }
  }
}
@include breakpoint($medium) {
  .view-staff {
    .views-row  {
      flex: 0 1 25%;
    }
  }
}

.field-name-field-staff-group {
  .view-content {
    display: flex;
    flex-wrap: wrap;
  }
  .views-row  {
    img {
      border-radius: 100%;
    }
    flex: 0 1 50%;
    text-align: center;
    padding: 20px;
    margin-bottom: 20px;
    h4 {
      color: $brand-success;
    }
  }
  @include breakpoint($medium) {
      .views-row  {
        flex: 0 1 25%;
      }
    }
}
