.node-type-staff {
  .field-name-field-profile-image {
    margin: 20px auto;
    text-align: center;
    img {
      border-radius: 100%;
    }
  }
  .field-name-field-full-name {
    text-align: center;
  }
  .field-name-field-job-title {
    text-align: center;
    font-size: 1.2em;
  }
  .field-name-field-regions-served {
    text-align: center;
    padding: 20px;
    background-color: mix($brand-primary, #fff, 30%);
    font-size: 1.2em;

    .field-label,
    .field-items,
    .field-item {
      display: inline-block;
    }
    .field-item {
      padding: 0 5px;
    }
  }
  .group-extra-info {

    padding: 5% 20px;
    .container {
      @include container;
      display: flex;

      .field {
        flex: 1 1 25%;
        .field-item {
          // margin-left: 10px;

          &:before {
            @extend .fa;
            @extend .fa-caret-right;
            padding-right: 5px;
            color: $brand-info;
          }
        }
      }
    }
  }
  .ds-2col-stacked-fluid .middle {
    .field-label {
      text-align: center;
      font-size: 1.2em;
    }
    background-color: $brand-primary;
    color: #fff;
    padding: 5% 20px;
  }
}
