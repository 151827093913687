// ------------------------------------
// Gloabl Vars.
// ------------------------------------

// Body/HTML Settings.
$body_font: $font-family-sans-serif; // Default font.
$body_fontsize: 16px; // Base or root font-size.
$body_lineheight: 1.5em;
$body_fontcolor: $gray-base; // Default font color.
$body_bg_color: #fff; // Page background color.

// Titles and Headings.
$title_fontfamily: null; // defaulting to body font;
$title_fontcolor: $gray; // This is for the H1.
$title_fontweight: 300;

$heading_fontfamily: $font-family-serif; // This is for the rest of the headings.
$heading_fontcolor: $title_fontcolor; // darken($blue, 3%); // This is for the rest of the headings.
$heading_fontweight: 300;

//** By default, this inherits from the `<body>`.
$headings-font-family:    inherit;
$headings-font-weight:    700;
$headings-line-height:    1.2;
$headings-color:          inherit;

//** Horizontal offset for forms and lists.
$component-offset-horizontal: 80px;
//** Text muted color
$text-muted:                  $gray-light;
//** Abbreviations and acronyms border color
$abbr-border-color:           $gray-light;
//** Headings small color
$headings-small-color:        $gray-light;
//** Blockquote small color
$blockquote-small-color:      $gray-light;
//** Blockquote font size
$blockquote-font-size:        ($font-size-base * 1.25);
//** Blockquote border color
$blockquote-border-color:     $gray-lighter;
//** Page header border color
$page-header-border-color:    $gray-lighter;
//** Width of horizontal description list titles
$dl-horizontal-offset:        $component-offset-horizontal;
//** Point at which .dl-horizontal becomes horizontal
// $dl-horizontal-breakpoint:    $grid-float-breakpoint;
//** Horizontal line color.
$hr-border:                   $gray-lighter;

// Butons and Links.
$button_bg_color: #7EA98D;
$button_bg_hovercolor: $brand-primary;
$button_fontcolor: #ffffff;
$button_radius: 5px;

$link: $brand-info; // Default link color.
$link_hover: #39774E; // Default link hover.
$link_active: $brand-warning; // Default link active and focus color.

$footer_link: $gray; // Footer link color.
$footer_link_hover: $brand-success; // Footer link hover.
$footer_link_active: #39774E; // Footer link color.

// Menus.
$menu: #ffffff; // Primary navigation color.
$menu_hover: #fff; // Primary navigation hover.
$menu_active: $menu_hover; // Primary navigation active and focus color.

$menu_bg: transparent; // Primary navigation background.
$menu_bg_hover: $brand-success; // Primary navigation hover background.
$menu_bg_active: $menu_bg_hover; // Primary navigation active and focus background.

$menu_font: null ; // Font used for primary navigation.
$menu_font_weight: 400;


// Layout and Structural Vars.
$header_bg:	$brand-primary;
$header_font: null;
$header_fontcolor: #fff;

$main_bg: $white-clr; // The main content aera.

$footer_color: $gray; // Font color.
$footer_bg: $gray-lightish; // Background color.
$footer_size: 0.8em; // Text size.


// Misc stuff.
$border_color: $gray; // Gloabl border color.

$vspace: 20px; // Vertical spacing unit.
$hspace: 20px; // Horizontal spacing unit.


// Local Tasks
$tabs_toggle_bg: #fff;
$tabs_toggle_text: $body_fontcolor;

$tabs_primary_link: lighten($body_fontcolor, 25%);
$tabs_primary_link_active: $link;

$tabs_primary_text: $body_fontcolor;
$tabs_primary_text_active: $body_fontcolor;

$tabs_primary_bg: transparent;
$tabs_primary_bg_hover: mix($tabs_primary_link_active, white, 5%);
$tabs_primary_bg_active: mix($tabs_primary_link_active, white, 5%);

$tabs_secondary_text: $white;
$tabs_secondary_text_active: $white;

$tabs_secondary_bg: $blue;
$tabs_secondary_bg_active: $ltblue;


// Tables.
$table_cell_padding: 10px;
$row_even: $smoke; // lighten($blue, 30%) Table even row styles
$row_odd: transparent; // Table odd row styles
$table_header: $row_even; // Table header row styles
