body {
  font-family: $body_font;
  font-size: $body_fontsize;
  line-height: $body_lineheight;
  color: $body_fontcolor;
}

html, html a, body {
   -webkit-font-smoothing: antialiased;
}

a {
  @include linkme;
}

strong, b {
  font-weight: bold;
}

ul, ol {
}

p {
  margin-bottom: 0.75em;
  line-height: 1.5em;
}

// @TODO Include Typey https://github.com/jptaranto/typey.
// h1 {
//   color: $title_fontcolor;
//   font-family: $title_font;
//   font-size: 1.75em;
//   font-weight: $title_fontweight;
//   line-height: 1.1em;
// }
//
// h2 {
//   font-size: 1.5em;
//   font-weight: 500;
// }
//
// h3 {
//   font-size: 1.25em;
// }
//
// h4, h5, h6 {
//   font-size: 1em;
// }
//
// h2, h3, h4, h5, h6 {
//   color: $heading_fontcolor;
//   font-family: $heading_font;
//   font-weight: $heading_fontweight;
//   line-height: 1.1em;
//   a {
//     @include linkme($title_fontcolor);
//   }
// }

// Headings
// -------------------------
h1 {
  text-align: center;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  color: $headings-color;

  small,
  .small {
    font-weight: normal;
    line-height: 1;
    color: $headings-small-color;
  }
}

h1 {
  font-family: $title_fontfamily;
  margin-bottom: 1em;
}

h2, h3, h4, h5, h6 {
  font-family: $heading_fontfamily;
}
h2 {
  color: $brand-primary;
}
h3, h4, h5, h6 {
  color: lighten($brand-primary, 10%);
}
// h1, h2 {
//   position: relative;
//   display: block;
//   &:after {
//     width: 46px;
//     border-bottom: soild 1px;
//     position: absolute;
//     bottom: -0.5em;
//     left: 50%;
//     margin-left: -23px;
//   }
//
// }


h2, .h2,
h3, .h3 {
  margin-top: 0;
  margin-bottom: ($line-height-computed / 2);
  small {
    font-size: 65%;
  }
}
h4, .h4,
h5, .h5,
h6, .h6 {
  margin-top: 0;
  margin-bottom: ($line-height-computed / 2);

  small,
  .small {
    font-size: 75%;
  }
}

h1, .h1 { font-size: $font-size-h1; }
h2, .h2 { font-size: $font-size-h2; }
h3, .h3 { font-size: $font-size-h3; }
h4, .h4 { font-size: $font-size-h4; }
h5, .h5 { font-size: $font-size-h5; }
h6, .h6 { font-size: $font-size-h6; }


footer a {
  @include linkme($footer_link, $footer_link_hover, $footer_link_active);
}

.item-list ul.pager {
  margin: 10px 0;
  li {
    padding: 0;
    margin: 0;
    display: inline-block;
    background-color: #fff;
    &.pager-current {
      background-color: $link_hover;
      color: #fff;
      padding: 8px 15px;
    }
    &:hover {
      background-color: $link_hover;
      color: #fff;
    }
  }

  li a {
    padding: 8px 15px;
    display: inline-block;
    &:link,
    &:visited {
      color: $link_hover;
    }
    &:hover,
    &:active,
    &:focus {
      color: #fff;
    }
  }
}
