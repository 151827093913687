.breadcrumb {
  margin: 0;
  padding: 10px 20px;
  background-color: $gray-light;
  display: block;
  text-align: center;

  ol {
    display: block;
    li {
      display: inline-block;
      color: $gray;
    }
  }
  .breadcrumb--last {
      padding: 10px;
      display: inline-block;
  }
  a {
    text-decoration: none;
    display: inline-block;
    padding: 10px;

    &:link,
    &:visited {
      color: $brand-success;
    }

    &:hover,
    &:focus {
      color: $brand-info-2;
    }

    &:active,
    &.active {
      color: $brand-success;
    }
  }
}
