label {}

legend {}

fieldset {}

fieldset .form-text { width: 100%; }



select {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	padding: 4px 10px;
}

input[type="checkbox"],
input[type="radio"] {
    width: auto;
    margin-right: 0.3em;
}

input[type="search"] {
    -webkit-appearance: none;
    border-radius: 0;
}

input, textarea {
	color: $gray;
	border: 1px solid $gray-lighter;
	background-color: $gray-light;
	padding: 10px;
	width: 100%;
	border-radius: 5px;
}
.form-text,
.form-textarea {
}
@include breakpoint($medium) {
	input, textarea {
		width: auto;
	}
}

button,
.form-submit,
.button,
.nested-button a {
	@include button;
}
.button-outline,
.nested-button-outline a {
	@include button_outline;
}


// Fixing table draggers.
a.tabledrag-handle {
	height: 22px;
	width: 20px;
	display: inline-block;
}
a.tabledrag-handle .handle {
	height: 100%;
	width: 100%;
	margin: 0;
	padding: 0;
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAMAAAAMCGV4AAAAaVBMVEUAAABERERERERERERERERERERERERERERERERERERERERERERERERERERERERERERERERERERERERERERERERERERERERERERERERERERERERERERERERERERERERERERERERERERERERdclAKAAAAInRSTlMAAQIGBwkLDQ8QExQXIy5NUV9+hY+bwMHFyMrV5Ojx8/X7tyFtCQAAAHRJREFUCB1NwQcSgkAURMEHLqKE5ZOUrDv3P6SWUhTd/N3HGyfpqjXlEC2SlohD3qjJOTEZOzdewGTgJgduVuV9p877SrPjqbMHZVDftoOGtu0VSijDFUwG11DylQAmAxJ2ca065hC9Jb0iDsWmreAkmzJ+PlA9CVEeqx8DAAAAAElFTkSuQmCC);
	background-position: center;
	background-repeat: no-repeat;
}

.page-admin .form-actions {
	padding-top: $vspace;
}

.node-unpublished {
	background-color: mix(orange, white, 10%);
	padding: 30px 10px 10px;
	position: relative;
	&:before {
		content: "Unpublished content";
		color: orange;
		border-bottom: solid 1px $border_color;
		font-size: 0.65em;
		padding: 5px;
		line-height: 1em;

		text-transform: uppercase;
		@include clearfix;

		position: absolute;
		top: 0;
	}
}
