.entity-paragraphs-item.paragraphs-item-contact-form {
  .field-name-contact-form {
    padding: 10px 20px;
    background-color: #fff;
    border-radius: 5px;
    input.form-text,
    input.form-textarea {
      width: 100%;
    }
  }
  .group-left {
    img {
      width: 100%;
      height: auto;
    }
  }
}
