#booking-confirm-form,
#booking-request-form,
#booking-step2-form {
  min-width: 320px;
  max-width: 800px;
  margin: 50px auto;
  padding: 40px;
  text-align: center;
  // border: solid 1px $brand-info;
  border-radius: 5px;
  background-color: $gray-lightish;
  input.form-submit{
    width: 100%;
  }
  .form-item-date-date label {
    display: none;
  }
  .booking-confirm-list {
    padding: 0;
    margin: 1em 0;
    li {
      list-style: none;
      padding: 0;
      margin: 0;

    }
  }
}

.node-booking-room {
  @include container;
  margin-bottom: 50px;
  .field-name-field-available-time-blocks,
  .field-name-field-available-days {
    width: 50%;
    float: left;
    .field-item {
      font-size: 1.25em;
      line-height: 1.5em;
      &:before {
        @extend .fa;
        @extend .fa-caret-right;
        color: $brand-success;
        padding-right: 5px;
      }
    }
  }
  .field-name-field-room-price {
    margin: 20px 0;
    .field-item {
      font-size: 2.25em;
      font-weight: bold;
      color: $brand-success;
      display: block;
      padding: 10px 0;
    }
  }
  .group-footer {
    > .field {
      display: inline-block;
    }
  }
}

.view-booking {
  @include container;
  position: relative;
  .view-header {
    font-size: 1.25em;
  }
  .views-exposed-form {
    text-align: center;
    padding: 20px;
    margin-bottom: 20px;
    // background-color: $gray-lighter;
    border-bottom: solid 2px $brand-primary;
    border-top: solid 1px $brand-primary;

    .views-exposed-widgets {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
    }
    .views-exposed-widget {
      float: none;
      flex: 0 1 33%;
      select, input {
        width: 90%;
        margin: auto;
      }
    }
    #edit-group-wrapper {
      flex: 1 1 100%;
      text-align: left;

      > label {
        padding-bottom: 10px;
      }

      .form-type-bef-link {
        display: inline-block;
        a {
          display: block;
          padding: 3px 6px;
          line-height: 1.2;
          border-radius: 6px;
          border: 1px solid;
        }
      }
      .form-type-bef-link + .form-type-bef-link  {
        margin-left: 10px;
      }
    }
  }

  .views-row {
    padding-bottom: 40px;
    margin-bottom: 40px;
    border-bottom: dotted 2px $brand-primary;
    position: relative;
    .field-name-field-room-photos {
      float: right;
      margin: 0 0 20px 20px;
    }
    .views-field-field-available-time-blocks,
    .views-field-field-available-days {
      padding: 10px 0;
      clear: right;
      .views-label {
        font-weight: bold;
        color: $brand-info;
      }
      .item-list ul {
        margin: 10px 0;
        padding: 0;
      }
      .item-list ul li {
        list-style: none;
        font-weight: bold;
        padding: 3px 10px;
        border-radius: 10px;
        margin: 5px 10px;
        color: $brand-info;
        &:before {
          @extend .fa;
          padding-right: 5px;
        }
      }
    }
    .views-field-field-available-days ul {
      li {
        display: inline-block;
      }
      li:before {
        @extend .fa-calendar-o;
      }
    }
    .views-field-field-available-time-blocks ul {
      column-count: 4;

      li:before {
        @extend .fa-clock-o;
      }
    }
    .views-field-view-node,
    .views-field-nid {
      display: inline-block;
      margin-right: 10px;
    }
    .views-field-view-node a {
      @include button($brand-info);
    }
    .views-field-nid a {
      @include button;
    }
    .price {
      color: $brand-info;
      font-weight: bold;
    }
  }
}

.view-my-reservations,
.view-booking-reservation-admin {
  .views-exposed-widgets {
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    .views-widget {
      float: none;
      display: inline-block;
      flex: 1 1 auto;
    }
    .views-widget-filter-uid .form-autocomplete {
      width: 270px;
    }
  }
  .views-exposed-form .views-exposed-widget .form-submit {
    margin-top: 0;
  }

  @include container;
  margin-bottom: 20px;
  .views-field-field-time-slot-1,
  .views-field-field-time-slot {
    ul li {
      white-space: nowrap;
    }
  }
  .status-canceled {
    background-color: #eee;
    color: #999;
    .field-name-field-status {
    }
  }
  .status-booked {
    .field-name-field-status .field-item  {
      color: $brand-info-2;
      font-weight: bold;
      &:before {
        font-weight: normal;
        padding-right: 10px;
      }
      @extend .fa;
      @extend .fa-check;
    }
  }
}
