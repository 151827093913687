.entity-paragraphs-item.paragraphs-item-price-table {
  .field-collection-view,
  .field-collection-container {
    border: none;
  }
  .field-collection-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    @include container;

    .field-name-field-price-group {
      flex: 0 1 100%;
      padding: 20px 10px;
    }
    .field-name-field-price {
      font-size: 1.75em;
      font-weight: bold;
      color: $brand-success;
      text-align: center;
      padding: 10px;
    }
    .field-name-field-button {
      text-align: center;
    }
  }
  .field-name-field-feature-list {
    padding-left: 0;
    li {
      list-style: none;
      padding: 0 0 10px 0;
      line-height: 1.1em;

      &:before {
        color: $brand-success;
        @extend .fa;
        @extend .fa-check;
      }
    }
  }
}

@include breakpoint($small) {
  .entity-paragraphs-item.paragraphs-item-price-table .field-collection-container {
    .field-name-field-price-group {
      flex: 0 1 50%;
    }
  }
}
@include breakpoint($medium) {
  .entity-paragraphs-item.paragraphs-item-price-table .field-collection-container {
    .field-name-field-price-group {
      flex: 1 1 25%;
    }
  }
}
