// ------------------------------------
// Brand Colors.
// ------------------------------------
//== Colors
//
//## Gray and brand colors for use across Bootstrap.

$gray-base:               #000;
$gray-darker:             #9F9F9F;
$gray-dark:               #1c202b;
$gray:                    #b7b7b7;
$gray-light:              #f6f6f6 ;
$gray-lighter:            #d7d7d7;
$gray-lightish:           #efefef;

// $brand-primary:           #282e3e;
$brand-primary:           #6D8790;
// $brand-success:           #82c434;
$brand-success:           #4D6F7A;
// $brand-info:              #14a5eb;
$brand-info:              #3C456F;
$brand-warning:           #f69004;
$brand-danger:            #e75854;

$brand-info-2:            #547abb;
$brand-warning-2:         #f9be30;
$white-clr:               #fff;
$color-vida-loca:               #5b8924;


// ------------------------------------
// Generic Colors.
// ------------------------------------
$green: #006341;
$red: #b61f28;
$blue: #0F7A96;
$ltblue: #50B0C9;
$orange: #C2531A;


// ------------------------------------
// Generic Neutral Colors.
// ------------------------------------
$black: #000;
$white: #ffffff;
$smoke: #f5f5f5;

$ltgray: #eee;
$mdgray: #777;
$gray: #555;
$dkgray: #333333;

// SASS style sheet */
// Palette color codes */
// Palette URL: http://paletton.com/#uid=53p0u0k85sV1FNH4u-LclovgPkt */

// Feel free to copy&paste color codes to your application */


// As hex codes */

$color-primary-0: #6D8790;	// Main Primary color */
$color-primary-1: #CCD5D7;
$color-primary-2: #96A8AE;
$color-primary-3: #4D6F7A;
$color-primary-4: #325966;

$color-secondary-1-0: #7A819D;	// Main Secondary color (1) */
$color-secondary-1-1: #D3D5DD;
$color-secondary-1-2: #A3A7BA;
$color-secondary-1-3: #586085;
$color-secondary-1-4: #3C456F;

$color-secondary-2-0: #7EA98D;	// Main Secondary color (2) */
$color-secondary-2-1: #D7E3DB;
$color-secondary-2-2: #A9C5B3;
$color-secondary-2-3: #588F6B;
$color-secondary-2-4: #39774E;



// As RGBa codes */

$rgba-primary-0: rgba(109,135,144,1);	// Main Primary color */
$rgba-primary-1: rgba(204,213,215,1);
$rgba-primary-2: rgba(150,168,174,1);
$rgba-primary-3: rgba( 77,111,122,1);
$rgba-primary-4: rgba( 50, 89,102,1);

$rgba-secondary-1-0: rgba(122,129,157,1);	// Main Secondary color (1) */
$rgba-secondary-1-1: rgba(211,213,221,1);
$rgba-secondary-1-2: rgba(163,167,186,1);
$rgba-secondary-1-3: rgba( 88, 96,133,1);
$rgba-secondary-1-4: rgba( 60, 69,111,1);

$rgba-secondary-2-0: rgba(126,169,141,1);	// Main Secondary color (2) */
$rgba-secondary-2-1: rgba(215,227,219,1);
$rgba-secondary-2-2: rgba(169,197,179,1);
$rgba-secondary-2-3: rgba( 88,143,107,1);
$rgba-secondary-2-4: rgba( 57,119, 78,1);



// Generated by Paletton.com © 2002-2014 */
// http://paletton.com */
