.entity-paragraphs-item.paragraphs-item-call-to-action {
  background-color: $brand-primary;
  color: #fff;
  h2 {
    text-align: center;
    color: #fff;
    margin-bottom: 1.5em;
  }
  .group-footer {
    text-align: center;
    padding: 40px 0 20px 0;

  }
  .group-left {
    img {
      width: 100%;
      height: auto;
    }
  }
}
