.paragraphs-item-feature-lists {
  h3 {
    color: $brand-primary;
  }
  .field-collection-view,
  .field-collection-container {
    border: none;
  }
  .content {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    @include container;

    .field-name-field-feature-group {
      flex: 0 1 50%;
      padding: 20px;
      margin: 0 0 20px 0;
    }
    .icon .fa {
      width: 120px;
      height: 120px;
      font-size: 100px;
      line-height: 120px;
      color: $brand-success;
    }
    ul.field-name-field-feature-list {
      padding-left: 0;
      li {
        list-style: none;
        padding-left: 0;
        margin-bottom: 10px;
        &:before {
          @extend .fa;
          @extend .fa-caret-right;
          color: $brand-success;
        }
      }
    }
  }
}
@include breakpoint($medium) {
  .paragraphs-item-feature-lists .content {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    .field-name-field-feature-group {
      flex: 1 1 25%;
      padding: 20px;
      margin: 0 0 20px 0;
    }
  }
}
