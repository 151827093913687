.view-banner {
  .view-content {
  // padding: 10% 0;
    // @include container;
    text-align: center;
    color: #fff;

    img {
      width: 100%;
      height: auto;
      vertical-align: bottom;
    }

    h2 {
      color: #fff;
    }
    .field-name-field-banner-text {
      font-size: 1.5em;
      position: absolute;
      top: 35%;
      left: 10%;
      right: 10%;
      margin-top: 60px;
    }
    .field-name-field-banner-headline {
      position: absolute;
      top: 35%;
      left: 0;
      right: 0;
    }
  }
  .text-wrapper {
    position: relative;
    &.mask {
      background-color: rgba(0,0,0,0.25);
    }
  }
}

#block-views-banner-page-banner {
  background-position: center center;
  background-size: cover;

  div.contextual-links-wrapper {
    top: 140px;
  }
}

.no-banner .l-page {
  padding-top: 150px;
}
.no-banner .l-header.headroom {
  background-color: $brand-primary;
}
