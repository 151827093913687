*,
*:before,
*:after {
  box-sizing: border-box;
}

.l-header,
.l-main,
.l-footer {
  @include clearfix();
}

@include breakpoint($xsmall) {
  .l-branding {
    text-align: center;
  }
}

@include breakpoint($small) {
  .l-branding {
    @include span(2);
    text-align: left;
  }
  .region-navigation {
    @include span(last 10);
  }
}

@include breakpoint($medium) {
  // Two Sidebars.
  .two-sidebars {
    .l-content {
      @include span(isolate 6 at 4);
    }
    .region-sidebar-first {
      @include span(3);
    }
    .region-sidebar-second {
      @include span(last 3);
    }
  }
  // Left/First Sidebar.
  .one-sidebar.sidebar-first {
    .l-content {
      @include span(last 8);
    }
    .region-sidebar-first {
      @include span(4);
    }
  }

  // Right/Second Sidebar.
  .one-sidebar.sidebar-second {
    .l-content {
      @include span(8);
    }
    .region-sidebar-second {
      @include span(last 4);
    }
  }

  // HEADER regions.
  .l-branding {
    @include span(2);
  }
  .region-navigation {
    @include span(last 10);
  }
  // .region-header {
  //   @include span(12);
  // }


  // FOOTER Regions.
  .l-footer .container {
    @include container;
  }
  .region-footer {
    @include span(12);
  }
  .region-footer-a {
    @include span(3);
  }
  .region-footer-b {
    @include span(3);
  }
  .region-footer-c {
    @include span(3);
  }
  .region-footer-d {
    @include span(last 3);
  }
}


// Making DS layouts responsive.
@include breakpoint($medium) {

  .ds-1col > .inner {
    @include container;
  }


  /**
  * @file
  * Styling for the ds-2col-stacked-fluid template.
  */

  .ds-2col-stacked-fluid > .group-left {
    float: left; /* LTR */
    width: 50%;
    padding-right: 10px;
  }

  [dir="rtl"] .ds-2col-stacked-fluid > .group-left {
    float: right;
  }

  .ds-2col-stacked-fluid > .group-right {
    float: right; /* LTR */
    width: 50%;
    padding-left: 10px;

  }

  [dir="rtl"] .ds-2col-stacked-fluid > .group-right {
    float: left;
  }

  .ds-2col-stacked-fluid.group-one-column > .group-left,
  .ds-2col-stacked-fluid.group-one-column > .group-right {
    width: 100%;
    float: none;
  }

  .ds-2col-stacked-fluid > .group-footer {
    clear: both;
  }


  .ds-2col-stacked-fluid .inner > .group-left {
    float: left; /* LTR */
    width: 50%;
    padding-right: 10px;
  }

  [dir="rtl"] .ds-2col-stacked-fluid .inner > .group-left {
    float: right;
  }

  .ds-2col-stacked-fluid .inner > .group-right {
    float: right; /* LTR */
    width: 50%;
    padding-left: 10px;

  }

  [dir="rtl"] .ds-2col-stacked-fluid .inner > .group-right {
    float: left;
  }

  .ds-2col-stacked-fluid.group-one-column .inner > .group-left,
  .ds-2col-stacked-fluid.group-one-column .inner > .group-right {
    width: 100%;
    float: none;
  }

  .ds-2col-stacked-fluid .inner > .group-footer {
    clear: both;
  }
  .ds-2col-stacked-fluid .inner {
    @include container;
  }

  /**
  * @file
  * Styling for the ds-2col-stacked template.
  */

  .ds-2col-stacked > .group-left {
    float: left; /* LTR */
    width: 50%;
  }

  [dir="rtl"] .ds-2col-stacked > .group-left {
    float: right;
  }

  .ds-2col-stacked > .group-right {
    float: right; /* LTR */
    width: 50%;
  }

  [dir="rtl"] .ds-2col-stacked > .group-right {
    float: left;
  }

  .ds-2col-stacked > .group-footer {
    clear: both;
  }

  /**
  * @file
  * Styling for the ds-2col template.
  */

  .ds-2col > .group-left {
    float: left; /* LTR */
    width: 50%;
    padding-right: 10px;
  }

  [dir="rtl"] .ds-2col > .group-left {
    float: right;
  }

  .ds-2col > .group-right {
    float: left; /* LTR */
    width: 50%;
    padding-left: 10px;
  }

  [dir="rtl"] .ds-2col > .group-right {
    float: right;
  }


  /**
  * @file
  * Styling for the ds-2col template.
  */

  .ds-2col .inner > .group-left {
    float: left; /* LTR */
    width: 50%;
    padding-right: 10px;
  }

  [dir="rtl"] .ds-2col .inner > .group-left {
    float: right;
  }

  .ds-2col .inner > .group-right {
    float: left; /* LTR */
    width: 50%;
    padding-left: 10px;
  }

  [dir="rtl"] .ds-2col .inner > .group-right {
    float: right;
  }
  .ds-2col > .inner {
    @include container;
  }

  .ds-2col.group-one-column .inner > .group-left,
  .ds-2col.group-one-column .inner > .group-right {
    width: 100%;
    float: none;
  }

  /**
  * @file
  * Styling for the ds-3col-equal template.
  */

  .ds-3col-equal > .group-left {
    width: 33%;
    float: left; /* LTR */
    padding-right: 10px;
  }

  [dir="rtl"] .ds-3col-equal > .group-left {
    float: right;
  }

  .ds-3col-equal > .group-middle {
    width: 34%;
    float: left; /* LTR */
    padding-left: 10px;
    padding-right: 10px;
  }

  [dir="rtl"] .ds-3col-equal > .group-middle {
    float: right;
  }

  .ds-3col-equal .inner > .group-right {
    width: 33%;
    float: right; /* LTR */
    padding-left: 10px;
  }

  [dir="rtl"] .ds-3col-equal .inner > .group-right {
    float: left;
  }

  .ds-3col-equal .inner > .group-left {
    width: 33%;
    float: left; /* LTR */
    padding-right: 10px;
  }

  [dir="rtl"] .ds-3col-equal .inner > .group-left {
    float: right;
  }

  .ds-3col-equal .inner > .group-middle {
    width: 34%;
    float: left; /* LTR */
    padding-left: 10px;
    padding-right: 10px;
  }

  [dir="rtl"] .ds-3col-equal .inner > .group-middle {
    float: right;
  }

  .ds-3col-equal .inner > .group-right {
    width: 33%;
    float: right; /* LTR */
    padding-left: 10px;
  }

  [dir="rtl"] .ds-3col-equal .inner > .group-right {
    float: left;
  }

  .ds-3col-equal > .inner {
    @include container;
  }


  /**
  * @file
  * Styling for the ds-3col-stacked-equal template.
  */

  .ds-3col-stacked-equal > .group-header {
    clear: both;
  }

  .ds-3col-stacked-equal > .group-left {
    width: 33%;
    float: left; /* LTR */
  }

  [dir="rtl"] .ds-3col-stacked-equal > .group-left {
    float: right;
  }

  .ds-3col-stacked-equal > .group-middle {
    width: 34%;
    float: left; /* LTR */
  }

  [dir="rtl"] .ds-3col-stacked-equal > .group-middle {
    float: right;
  }

  .ds-3col-stacked-equal > .group-right {
    width: 33%;
    float: right; /* LTR */
  }

  [dir="rtl"] .ds-3col-stacked-equal > .group-right {
    float: left;
  }

  .ds-3col-stacked-equal > .group-footer {
    clear: both;
  }

  /**
  * @file
  * Styling for the ds-3col-stacked-fluid template.
  */

  .ds-3col-stacked-fluid > .group-header {
    clear: both;
  }

  .ds-3col-stacked-fluid > .group-left {
    width: 25%;
    float: left; /* LTR */
  }

  [dir="rtl"] .ds-3col-stacked-fluid > .group-left {
    float: right;
  }

  .ds-3col-stacked-fluid > .group-middle {
    width: 100%;
    float: left; /* LTR */
  }

  [dir="rtl"] .ds-3col-stacked-fluid > .group-middle {
    float: right;
  }

  .ds-3col-stacked-fluid.group-one-sidebar > .group-middle {
    width: 75%;
  }

  .ds-3col-stacked-fluid.group-two-sidebars > .group-middle {
    width: 50%;
  }

  .ds-3col-stacked-fluid > .group-right {
    width: 25%;
    float: right; /* LTR */
  }

  [dir="rtl"] .ds-3col-stacked-fluid > .group-right {
    float: left;
  }

  .ds-3col-stacked-fluid > .group-footer {
    clear: both;
  }

  /**
  * @file
  * Styling for the ds-3col-stacked template.
  */

  .ds-3col-stacked > .group-header {
    clear: both;
  }

  .ds-3col-stacked > .group-left {
    width: 25%;
    float: left; /* LTR */
  }

  [dir="rtl"] .ds-3col-stacked > .group-left {
    float: right;
  }

  .ds-3col-stacked > .group-middle {
    width: 50%;
    float: left; /* LTR */
  }

  [dir="rtl"] .ds-3col-stacked > .group-middle {
    float: right;
  }

  .ds-3col-stacked > .group-right {
    width: 25%;
    float: right; /* LTR */
  }

  [dir="rtl"] .ds-3col-stacked > .group-right {
    float: left;
  }

  .ds-3col-stacked > .group-footer {
    clear: both;
  }

  /**
  * @file
  * Styling for the ds-3col template.
  */

  .ds-3col > .group-left {
    width: 25%;
    float: left; /* LTR */
    padding-right: 10px;
  }

  [dir="rtl"] .ds-3col > .group-left {
    float: right;
  }

  .ds-3col > .group-middle {
    width: 50%;
    float: left; /* LTR */
    padding-left: 10px;
    padding-right: 10px;
  }

  [dir="rtl"] .ds-3col > .group-middle {
    float: right;
  }

  .ds-3col > .group-right {
    width: 25%;
    float: right; /* LTR */
    padding-left: 10px;
  }

  [dir="rtl"] .ds-3col > .group-right {
    float: left;
  }

  /**
  * @file
  * Styling for the ds-4col template.
  */

  .ds-4col > .group-first {
    width: 25%;
    float: left; /* LTR */
    padding-right: 10px;
  }

  [dir="rtl"] .ds-4col > .group-first {
    float: right;
  }

  .ds-4col > .group-second {
    width: 25%;
    float: left; /* LTR */
    padding-left: 10px;
    padding-right: 10px;
  }

  [dir="rtl"] .ds-4col > .group-second {
    float: right;
  }

  .ds-4col > .group-third {
    width: 25%;
    float: left; /* LTR */
    padding-left: 10px;
    padding-right: 10px;
  }

  [dir="rtl"] .ds-4col > .group-third {
    float: right;
  }

  .ds-4col > .group-fourth {
    width: 25%;
    float: left; /* LTR */
    padding-left: 10px;
  }

  [dir="rtl"] .ds-4col > .group-fourth {
    float: right;
  }

  /**
  * @file
  * Styling for the ds_2col_fluid template.
  */

  .ds-2col-fluid > .group-left {
    float: left; /* LTR */
    width: 50%;
    padding-right: 10px;
  }

  [dir="rtl"] .ds-2col-fluid > .group-left {
    float: right;
  }

  .ds-2col-fluid > .group-right {
    float: right; /* LTR */
    width: 50%;
    padding-left: 10px;
  }

  [dir="rtl"] .ds-2col-fluid > .group-right {
    float: left;
  }

  .ds-2col-fluid.group-one-column > .group-left,
  .ds-2col-fluid.group-one-column > .group-right {
    width: 100%;
    float: none;
  }

  /**
  * @file
  * Display Suite Two Column 66 33 stylesheet.
  */

  .ds-two-columns .inner > .ds-left {
    float: left;
    width: 66.6%;
  }

  .ds-two-columns .inner > .ds-right {
    float: left;
    width: 33.3%;
  }

  .ds-cta-two-columns .inner {
    display: flex;
    align-items: center;

    .ds-left {
      flex: 1 1 74%;
    }
    .ds-right {
      flex: 1 1 25%;
      text-align: center;
    }
  }
}
