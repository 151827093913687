.entity-paragraphs-item {
  padding: 5% $hspace;
  &:nth-child(odd) {
    background-color: $gray-light;
  }
}
.paragraphs-item-large-text {
  .content {
    @include container;
  }
}

.paragraphs-item-two-column,
.paragraphs-item-three-column {
   img {
    width: 100%;
    height: auto;
  }
}
