.entity-paragraphs-item.paragraphs-item-map {
  .inner {

    > .group-left {
      float: none;
    }
    > .group-right {

      float: none;
      position: relative;
      z-index: 1;
      &:before {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: -1;

        @extend .fa;
        @extend .fa-spinner;
        @extend .fa-spin;
      }
    }
  }
  @include breakpoint($medium) {
    .inner {
      display: flex;
      > .group-left {
        flex: 1 1 50%;
        float: none;
      }
      > .group-right {
        flex: 1 1 50%;
      }
    }
  }
}
