ul.main-menu,
ul.sf-main-menu {
  @include clearfix;

  margin: 0;
  > li > a {
    margin-right: 10px;
    display: block;
    padding: 10px 15px;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    font-family: $font-family-serif;

    &:link,
    &:visited {
      color: $menu;
      background-color: $menu_bg;
    }

    &:hover,
    &:focus {
      color: $menu_hover;
      background-color: $menu_bg_hover;
    }

    &:active,
    &.active {
      color: $menu_active;
      background-color: $menu_bg_active;
    }
  }

  > li.active-trail > a {
    &:link,
    &:visited {
      color: $menu_active;
      background-color: $menu_bg_active;
    }
  }

  > li > ul > li {
    a {
      display: block;
      padding: 10px;
      font-weight: bold;

      &:link,
      &:visited {
        color: $menu_bg_hover;
      }

      &:hover,
      &:focus {
        color: $menu;
      }

      &:active,
      &.active {
        color: $menu;
      }
    }
  }
  ul {
    background-color: lighten($brand-primary, 10%);
    padding: 10px;
    border-radius: 5px;
  }
}

.sf-accordion-toggle {
  text-align: center;
  background-color: lighten($brand-primary, 10%);
  a {
    display: block;
    padding: 10px;
  }
  @include breakpoint($small) {
    margin-top: 20px;
  }
}
