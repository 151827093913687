.l-main {
  background-color: $main_bg;

  .l-content {
    position: relative; // this is for the local tasks.
  }
}

.l-messages {
  @include container;
  margin: auto;
  padding-left: $hspace;
  padding-right: $hspace;
}
