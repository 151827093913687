.entity-paragraphs-item.paragraphs-item-cards {
  h3 {
    color: $brand-primary;
  }
  .field-collection-view,
  .field-collection-container {
    border: none;
  }
  .field-collection-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    @include container;

    .field-collection-view {
      flex: 0 1 100%;
      padding: 20px;
      margin: 0 0 20px 0;
      text-align: center;
    }
  }
  .icon-wrapper {
    height: 140px;
    margin: 40px 0;
    @include clearfix;
  }
  .card-icon {
    display: inline-block;
    position: relative;
    text-align: center;
    width: 120px;
    height: 120px;
    font-size: 60px;
    line-height: 120px;
    color: $brand-success;

    &:before {
      position: relative;
      display: inline-block;
      font-weight: 400;
      font-style: normal;
      speak: none;
      text-transform: none;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: inline-block;
      border: 1px solid $gray-lighter;
      transition: .4s;
      transform: rotate(45deg);
    }
    &:hover {
      &:after {
        transform: rotate(-90deg);
      }
    }

  }
  @include breakpoint($small) {
    .field-collection-container .field-collection-view {
      flex: 1 1 50%;
    }
  }
  @include breakpoint($medium) {
    .field-collection-container .field-collection-view {
      flex: 1 1 25%;
    }
  }
}
