#user-login,
#user-register-form,
#user-pass {
  max-width: 400px;
  min-width: 300px;
  margin: 20px auto;
  input {
    width: 100%;
  }

  padding: 20px;
  background-color: $brand-info;
  color: #fff;
  border-radius: 5px;
  margin-bottom: 40px;
}

.profile {
  @include container;
}
