.view-booking-availability {
  background-color: white;
  padding: 20px;
  
  .views-row {
    @include clearfix;
    .days {
      float: left;
      width: 50%;
    }
    .times {
      float: right;
      width: 50%;
    }
    ul, li {
      margin: 0;
      padding: 0;
      list-style: none;
    }
  }
}
