// Gradient Maker.
@mixin gradient($color1: #efefef, $color2: #fff, $direction: bottom) {
	background-color: $color1;
	background-image: linear-gradient($direction, $color1 0%, $color2 100%);
}

// Cleafix magic.
@mixin clearfix() {
    &:before,
    &:after {
        content: "";
        display: table;
    }
    &:after {
        clear: both;
    }
}


// Button Maker.
@mixin button($color: $button_bg_color, $text: $button_fontcolor, $radius: $button_radius, $color_hover: $button_bg_hovercolor) {
  color: $text !important; // overrides the text color in anchor tags.
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  text-shadow: none;
	letter-spacing: 0.12em;

  font-weight: bold;
	transition: .4s;

  outline: none;
  border: none;
  cursor: pointer;
  padding: 15px 30px;
  // margin-right: 5px;
  display: inline-block;

	border-radius: $radius;
  background-clip: padding-box;

  background-color: $color;

  &:link,
  &:visited {
  	background-color: $color;
  }

  &:hover,
  &:focus,
  &:active {
  	background-color: $color_hover;
  }
}

@mixin button_outline($color: $button_bg_color, $text: $button_fontcolor, $radius: $button_radius, $color_hover: $button_bg_hovercolor) {
  color: $color !important; // overrides the text color in anchor tags.
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  text-shadow: none;
	letter-spacing: 0.12em;

  font-weight: bold;
	transition: .4s;

  outline: none;
  border: none;
  cursor: pointer;
  padding: 15px 30px;
  // margin-right: 5px;
  display: inline-block;

	border-radius: $radius;
  background-clip: padding-box;

  background-color: transparent;

  &:link,
  &:visited {
  	background-color: transparent;
		border: solid 1px $color;
		color: $color;
  }

  &:hover,
  &:focus,
  &:active {
  	background-color: $color;
		border: solid 1px $color;
		color: $text !important; // overrides the text color in anchor tags.
  }
}


// Generates a link style with magic superpowers.
@mixin linkme($color: $link, $hover: $link_hover, $active: $link_active) {
  &:link,
  &:visited {
    color: $color;
    text-decoration: none;
  }

  &:hover,
  &:focus {
    color: $hover;
  }

  &:active,
  &.active {
    color: $active;
  }
}

@mixin linkmebg($bg: transparent, $bghover: transparent, $bgactive: transparent) {
  &:link,
  &:visited {
    background-color: $bg;
  }

  &:hover,
  &:focus {
    background-color: $bghover;
  }

  &:active,
  &.active {
    background-color: $bgactive;
  }
}

// Some generic pixie dust to make things fly toward Never Never Land.
@mixin eyecandy($property: all, $duration: 0.5s, $function: ease-in-out, $delay: 0) {
	transition-property: $property;
	transition-duration: $duration;
	transition-timing-function: $function;
	transition-delay: $delay;
}

@mixin animate($name, $duration: $AnimateDuration, $delay: 0, $iterate: 1) {
  $delay: $delay + '';

  @if (str-index($delay, s) == null) {
    $delay: unquote($delay + 's');
  }

  animation: $name $duration $delay $iterate;
}
