// ------------------------------------
// Global Fonts.
// ------------------------------------

// When importing fonts from Google make sure the URL is HTTPS or protocol agnostic.
// Font Awesome.
// @import url(//maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css);

//  from Google Fonts.
@import url(https://fonts.googleapis.com/css?family=Roboto:400,700);
@import url(https://fonts.googleapis.com/css?family=Roboto+Slab:400,700);

$font-family-sans-serif:  'Roboto', sans-serif;
$font-family-serif:      'Roboto Slab', serif;

$font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace;

$font-family-base:       $font-family-sans-serif;
$font-family-sec:        $font-family-serif;
$fa:                     'FontAwesome';

// Base FZ & LH (w/o units)
$base-text-size:          16;
$base-lh-size:            24;

// $base-vm-size:            (($base-text-size*100)/$template-width)*1vw;

$font-size-base:          $base-text-size * 1px;
$font-size-large:         ceil(($font-size-base * 1.25)); // ~18px
$font-size-small:         ceil(($font-size-base * 0.9)); // ~12px

$font-weight-base:        300;


//PSD FZ headers /FOR EDIT (w/o units)
$Size-h1:                 60;
$Size-h2:                 46;
$Size-h3:                 30;
$Size-h4:                 22;
$Size-h5:                 20;
$Size-h6:                 18;

//PSD LH headers /FOR EDIT (w/o units)
$Lh-h1:                   70;
$Lh-h2:                   52;
$Lh-h3:                   40;
$Lh-h4:                   30;
$Lh-h5:                   32;
$Lh-h6:                   28;


//Calculating FZ headers
$font-size-h1:            $Size-h1*1px;
$font-size-h2:            $Size-h2*1px;
$font-size-h3:            $Size-h3*1px;
$font-size-h4:            $Size-h4*1px;
$font-size-h5:            $Size-h5*1px;
$font-size-h6:            $Size-h6*1px;



//** line-height for headers /FOR EDIT
// $font-lh-h_: floor((PSD l-h / $font-size-h1));

$font-lh-h1:              ($Lh-h1 / $Size-h1);
$font-lh-h2:              ($Lh-h2 / $Size-h2);
$font-lh-h3:              ($Lh-h3 / $Size-h3);
$font-lh-h4:              ($Lh-h4 / $Size-h4);
$font-lh-h5:              ($Lh-h5 / $Size-h5);
$font-lh-h6:              ($Lh-h6 / $Size-h6);

//** Unit-less `line-height` for use in components like buttons.
$line-height-base:        ($base-lh-size / $base-text-size); // l-h/fz-base
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed:    floor(($font-size-base * $line-height-base)); // ~20px



//== Iconography
//
//## Specify custom location and filename of the included Glyphicons icon font. Useful for those including Bootstrap via Bower.

//** Load fonts from this directory.
$icon-font-path: "../fonts/";

//** File name for all font files.
$icon-font-name:           "glyphicons-halflings-regular";
$icon-font-awesome:        "fontawesome-webfont";
$icon-material-design:     "materialdesignicons-webfont";

//** Element ID within SVG icon file.
$icon-font-svg-id:         "glyphicons_halflingsregular";
$icon-font-awesome-id:     "fontawesomeregular";
$icon-material-design-id:  "materialdesigniconsregular";
