.view-slideshow {
  position: relative;
  text-align: center;

  img {
    width: 100%;
    height: auto;
    vertical-align: baseline;
    overflow: hidden;
  }

  .slide-text {
    width: 100%;
    position: absolute;
    z-index: 1;
    bottom: ($vspace * 6);
    left: $hspace;
    color: #fff;
    padding: $vspace $hspace;
    .container {
      @include container;
    }

    h2 {
      color: #fff;
      font-size: 6vw;
      font-weight: 800;
      line-height: 1em;
      text-shadow: 1px 1px 10px $gray;
      margin-bottom: 0.25em;
    }
    .field-name-slide-sub-headline {
      font-size: 2.5vw;
      font-weight: 800;
      line-height: 1em;
      margin-bottom: 0.55em;
      text-shadow: 1px 1px 10px $gray;
    }

    &.screen---light {
      color: #333;
      h2 {
        color: #333;
        text-shadow: 1px 1px 10px $smoke;
      }
      .field-name-field-sub-headline {
        text-shadow: 1px 1px 10px $smoke;
      }
    }
  }

  .views-field-edit-node {
    position: absolute;
    z-index: 3;
    bottom: $vspace;
    right: $hspace;
    a {
      display: inline-block;
      background-color: #fff;
      padding: 5px 10px;
      border-radius: 10px;
      font-size: 0.8em;
    }
  }

.owl-controls {
  position: absolute;
  bottom: $vspace;
  left: $hspace;
  width: 100%;
  z-index: 2;
}
  .owl-pagination {
    @include container;
    text-align: left;

    .owl-page {
      display: inline-block;
      span {
        height: 20px;
        width: 20px;
        margin: 0 10px 0 0;
        // border: solid 2px $brand_yellow;
        // background-color: $brand_drkblue;
      }
      &.active span {
        // background-color: $brand_yellow;
      }
    }
  }
  .owl-theme .owl-controls {
    margin-top: 0;

  }

  .screen---none {}
  .background.screen---dark {
    background-color: #000;
    img {
      opacity: 0.60;
    }
  }
  .background.screen---light {
    background-color: #fff;
    img {
      opacity: 0.45;
    }
  }
}
