body {
  background-color: $body_bg_color;
  &.admin-menu {
    margin-top: 29px;
  }
}

.l-header {
  background-color: $header_bg;
  padding: 0;
  color: $header_fontcolor;
  position: relative;

  .container {
    @include container;
  }
}
@include breakpoint($small) {
  .l-header {
    padding: 10px;
  }
}
@include breakpoint($medium) {
    .l-header {
      padding: $vspace $hspace;
    }
.headroom {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  @include eyecandy;
  background-color: transparent;
  .region-navigation {
    padding-top: 33px;
  }
}
.admin-menu .headroom {
  top: 29px;
}
.site-logo img {
  @include eyecandy;

}
.headroom.headroom--not-top {
  position: fixed;
  background-color: $header_bg;
  box-shadow: 0 0 5px $gray;

  .site-logo img {
    max-height: 75px;
  }
  .region-navigation {
    padding-top: 20px;
  }
}
}
