#block-menu-menu-footer .content {
  ul.menu {
    margin: 20px 0;
    padding: 0;
    text-align: center;
    li {
      list-style: none;
      list-style-image: none;
      padding: 0;
      margin: 0;
      display: inline-block;
      a {
        display: block;
        padding: 5px 10px;
      }
    }
  }
}
